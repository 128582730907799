
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NewIcon extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private articleOpenDate!: String

  private dateDiff(openDate: any) {
    const current = this.$dayjs(this.$store.state.time.currentDate)
    const open = this.$dayjs(openDate)
    return current.diff(open, 'days') < 7
  }
}
