
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ArticleCategoryType extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private articleCategory!: String

  @Prop({
    type: String,
    required: false
  })
  private classname: String

  @Prop({
    type: String,
    required: false
  })
  private used!: string

  private get articleCategoryType() {
    return this.articleCategory === 'ストーリー' ? 'STORY' : 'KNOWHOW'
  }
}
